import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import axios from "axios";
import _ from "lodash";
import { Ring } from "react-spinners-css";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import {
  SectionHeading,
  // Subheading as SubheadingBase,
  ClaimHeading as ClaimHeadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import ErrorIcon from '@material-ui/icons/Error';

import {createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Tooltip from "@material-ui/core/Tooltip";
import qs from 'qs';

import Box from '@material-ui/core/Box';

const NFTItalic = tw.span`italic`;
const NoWallet = tw.span`font-bold`;
const ContractAddress = tw.span`font-mono truncate overflow-hidden `;
const ContractDescription = tw.span`text-center truncate overflow-hidden max-w-sm`;

const GTMetaMask = tw.span`underline`;
const Container = tw.div`relative`;
const TwoColumn = tw.div`
  flex flex-col md:flex-row sm:flex-row  justify-between max-w-screen-xl 
  mx-auto py-20 pt-0 md:py-24 md:pt-0 
  sm:py-24 sm:pt-0 `;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:my-0 sm:my-0`;
// const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-0 md:my-10 sm:my-10`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

// const Image = styled.div((props) => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded bg-contain bg-no-repeat bg-center h-full`,
// ]);
const TextContent = tw.div`lg:py-4 md:py-4 sm:py-4 my-4 px-5 text-left md:text-left`;
const CHeading = tw(ClaimHeadingBase)`text-center md:text-left`;
// const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center truncate overflow-hidden sm:text-lg md:text-left text-base md:text-base w-1/2 lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Instruction = tw.p`mt-4 text-center sm:text-lg md:text-left text-base md:text-base w-1/2 lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0 mb-6`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
// const Textarea = styled(Input).attrs({ as: "textarea" })`
//   ${tw`h-24`}
// `;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8 `;
const RainbowICON = tw.span`text-center px-1`;
const search = window.location.search;
const claimId = new URLSearchParams(search).get('claimId');
console.log('BXL: claimID', claimId)

export default ({
  subheading = "Contact Us",
  heading = (
    <>
       <span tw="text-primary-500">Ship of Theseus</span>
      <wbr />
    </>
  ),
  description = "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
  textOnLeft = false,
  id = "contact",
}) => {

  const useStyles = makeStyles((theme: Theme) => {
    createStyles({
      table: {
        minWidth: 650,
        marginTop: 30
      },
      cheadingNFT: {
        fontStyle: 'italic'
      },
      root: {
        minWidth: 275,
        
      },
      errorCardContent: {
        backgroundColor: 'red'
      },
      bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
      title: {
        fontSize: 14,
      },
      pos: {
        marginBottom: 12,
      }
    })
      
  });
  const classes = useStyles();
  const [copiedText, setCopiedText] = useState();
  const [formState, setFormState] = useState();
  const [hideEthersInput, setHideEthersInput] = useState();

  const [itemID, setItemID] = useState();
  const [txnHash, setTxnHash] = useState();
  const [address, setETHAddress] = useState();

  const renderFormStates = ({ errors, handleSubmit, isSubmitting, getFieldProps }) => {
    switch (formState) {
      case 'Success':
        return (
          <TextContent>
            <CHeading><NFTItalic>NFT</NFTItalic> Received!</CHeading>
            <TextContent>You’re 1 of 20 proud owners of the Ship of Theseus NFT. Check it out in your Rainbow gallery.</TextContent>
            <TextContent>It may take a few minutes to get the NFTs in your wallet.</TextContent>
            <TextContent>
              <Box  pb={1} pt={1}>
                <Button  size="medium"  variant="outlined" color="default"  style={{maxWidth: "90%"}} href={'https://etherscan.io/tx/' + txnHash} target="_blank" fullWidth>
                  <ContractDescription>TRANSACTION: <ContractAddress> {txnHash} </ContractAddress> </ContractDescription>
                </Button>
              </Box>
            </TextContent>
            <TextContent>
              <Button size="medium" variant="outlined" color="secondary" style={{maxWidth: "90%"}} href={'https://rainbow.me/' + address} target="_blank" fullWidth>
                <RainbowICON style={{transform: "scaleX(-1)"}}>🌈</RainbowICON> VIEW on Rainbow.me
              </Button>
            </TextContent>
           </TextContent>
          )
      default:
        return (
          <TextContent>
            <CHeading>Claim <NFTItalic>NFT</NFTItalic></CHeading>
            {
              hideEthersInput ? (<></>) : (<Instruction>Share the wallet address you’d like the NFT sent to.</Instruction>)
            }

            <Form loading={isSubmitting} onSubmit={handleSubmit}>
            { 
            (
              hideEthersInput ?  
              <>
                <Card className={classes.root} variant="outlined" bgcolor="primary.main">
                  <CardContent className={classes.errorCardContent} style={{backgroundColor: "#FFF4F4"}}>
                  <CardHeader
                      avatar={
                        <ErrorIcon/>
                      }
                      title="Claim failed"
                      subheader={<ErrorMessage name="ETHAddress" render={msg => <span>{msg}</span>}/>}
                  />
                  </CardContent>
                  <CardActions style={{backgroundColor: "#FFF4F4"}}>
                    <Button size="small">Please contact Zeina</Button>
                  </CardActions>
                </Card>
                 
              </>:
              <>
                <label htmlFor="eth_adddress">Ethereum Address</label>
                <Input
                  id="ETHAddress"
                  label="ETHAddress"
                  name="ETHAddress"
                  placeholder="example: 0x12345..."
                  {...getFieldProps("ETHAddress")} />
                <ErrorMessage name="ETHAddress" render={msg => <span style={{color:"red"}}>{msg}</span>}/>
                { <SubmitButton
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || !_.isEmpty(errors)}
                  >
                    {isSubmitting ? (
                      <>
                        <span> 
                          <Ring
                            color="#f7fafc"
                            size={23}
                            style={{ position: "absolute", marginLeft: "-28px" }}
                          />
                          Sending NFT to wallet
                        </span>
                      </>
                    ) : (
                      "Claim NFT"
                    )}
                  </SubmitButton>
                }
              </>
            )
          }
            </Form>
            <Divider/>
            <TextContent>
              <p>
                <NoWallet>No wallet?! </NoWallet>
                Set one up using &nbsp; 
                <a href="https://metamask.io/download.html" target="_blank" rel="noopener noreferrer">
                  <GTMetaMask>
                    MetaMask Wallet
                  </GTMetaMask>
                </a>
                &nbsp; or &nbsp;
                <a href="https://rainbow.me/" target="_blank" rel="noopener noreferrer">
                  <GTMetaMask>
                    Rainbow Wallet
                  </GTMetaMask>
                </a>
              </p>
            </TextContent>
            <Divider/>
            <TextContent>
              <p>
                made with ♥ by BlockX Labs
              </p>
            </TextContent>
          </TextContent>
        )
    }
  }
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  return (
    <Formik
      initialValues={{
        ETHAddress: "",
      }}
      validationSchema={yup.object().shape({
        ETHAddress: yup.string().required("ETH Address is required").max(200),
      })}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        //send request
        try {
          // DEBUG
          // const sendNFTuri = "http://localhost:3030";
          const sendNFTuri = "https://be.shopify-nft.com";

          const data = qs.stringify({
            'claimId': claimId,
            'ETHAddress': values.ETHAddress 
          });
          const result = await axios.post(
            `${sendNFTuri}/api/nft/mint`,
            data,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
              },
            }
          );
          console.log('BXL: result', result)
          // TODO: success show link to make address 
          //  values.ETHAddress 
          actions.setFieldError("ETHAddress", `Success`)
          setFormState("Success")
          setItemID(result.data.item_id)
          setTxnHash(result.data.transaction_hash)
          setETHAddress(values.ETHAddress)
          // actions.resetForm();
        } catch (err) {
          console.log('BXL: ERROR MINTING', err.response.data.error)

          let statusMessage = `ERROR ${err.response.data.error}`;
          let hideEthersInput = false; 
          switch (err.response.status) {
            case 404:
            case 422:
              break;
            case 500:
              hideEthersInput = true;
              break;
            default:
              break;    
          }

          console.log('BXL: ERROR MINTING', err.response.data.error)
          // https://formik.org/docs/api/formik#setfielderror-field-string-errormsg-string--void
          actions.setFieldError("ETHAddress", statusMessage)
          setHideEthersInput(hideEthersInput)
         
          // actions.setStatus({"ETHAddress":`CLAIM NFT ERROR: ${err.response.data.error}`})
          // alert(`CLAIM NFT ERROR: ${err.response.data.error}`)
        } finally {
          // DONT reset form if you want to show user server side errors
          // actions.resetForm();
          actions.setSubmitting(false);
        }
      }}
      render={({ errors, handleSubmit, isSubmitting, getFieldProps }) => (
        <Container id={id}>
          <TwoColumn>
            <TextColumn>
              <Heading>{heading}</Heading>
              <CopyToClipboard
                text={description}
                onCopy={() => setCopiedText(description)}
              >
              <Tooltip
                title={
                  copiedText === description
                    ? "This was Copied!"
                    : "Copy To Clipboard"
                }
                placement="top"
              >
              <Description>Contract: 
                <ContractAddress> {description}</ContractAddress>
                </Description>
              </Tooltip>
              </CopyToClipboard>
              <TextContent>
                The ship on which Theseus sailed with the youths and returned in safety, 
                the thirty-oared galley, was preserved by the Athenians down to the time 
                of Demetrius Phalereus. They took away the old timbers from time to time, 
                and put new and sound ones in their places, so that the vessel became a 
                standing illustration for the philosophers in the mooted question of 
                growth, some declaring that it remained the same, others that it was not 
                the same vessel. - The Life of Theseus, Plutarch
              </TextContent>
              
              <TextContent>
               <TableContainer component={Paper}>
                 <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <div>EVENT</div>
                          <div>Shopify Senior Leadership Burst</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <div>DATE</div>
                          <div>October 4, 2021</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <div>ARTIST</div>
                          <div>Honorroller</div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </TextContent>
            </TextColumn>
            <TextColumn className="right-card" textOnLeft={textOnLeft}>
              {renderFormStates({ errors, handleSubmit, isSubmitting, getFieldProps })}
            </TextColumn>
          </TwoColumn>
        </Container>

      )}
    />
  );
};
